<template>
    <div class="breadcrumb">
        <div class="breadcrumb__list">
            <router-link
                class="breadcrumb__list__item"
                v-for="bread in state.breadcrumb"
                v-bind:key="bread"
                :to="bread.route"
            >
                {{ bread.name }}
            </router-link>
        </div>
    </div>
</template>
<script lang="ts" src="@/presentation/components/breadcrumb/Breadcrumb.ts" scoped>
</script>
<style lang="scss" src="@/presentation/components/breadcrumb/Breadcrumb.scss" scoped>

</style>
