import { reactive, defineComponent, onMounted } from "vue";
import { useRouter } from "vue-router";
import Breadcrumb from "@/presentation/components/breadcrumb/Breadcrumb.vue";
import { useField, useForm } from "vee-validate";
import * as yup from "yup";
import LanguageUtil from "@/commons/LanguageUtil";
import ErrorMessage from "@/presentation/components/error-message/ErrorMessage.vue";
import MasterInteractor from "@/domain/usecases/MasterInteractor";
import DIContainer from "@/core/DIContainer";
import Organization from "@/domain/entity/Organization";
import { useStore } from "vuex";
import RoutePath from "@/commons/RoutePath";
import Input from "@/presentation/components/input/Input.vue";
import * as agh from "agh.sprintf";
import Constant from "@/commons/Constant";
export default defineComponent({
    name: "OrganizationRegisterDetailProfile",
    components: {
        Breadcrumb: Breadcrumb,
        ErrorMessage: ErrorMessage,
        Input: Input
    },
    setup: function () {
        var store = useStore();
        var masterInteractor = DIContainer.instance.get(MasterInteractor);
        /* eslint-disable @typescript-eslint/camelcase */
        var router = useRouter();
        var state = reactive({
            aLang: LanguageUtil.getInstance().lang,
            listBreadcrumb: [
                {
                    route: '/', name: "TOP"
                },
                {
                    route: '', name: "基本情報入力"
                }
            ],
            listCorporation: [],
            listPerfecture: [],
            listCities: [],
            showDropdownList: false,
            namekana: "",
            loadingDataDropdown: false
        });
        //validate
        var _a = useForm({
            initialValues: new Organization()
        }), isSubmitting = _a.isSubmitting, handleSubmit = _a.handleSubmit, values = _a.values, setValues = _a.setValues, errors = _a.errors;
        var name = useField("name", yup
            .string()
            .required(state.aLang.validation_required)
            .max(100, agh.sprintf(state.aLang.validation_max_length, 100))).value;
        var corporate_number = useField("corporate_number", function (value) {
            return true;
        }).value;
        var project_place_prefecture_name = useField("project_place_prefecture_name", function (value) {
            return true;
        }).value;
        var project_place_city_name = useField("project_place_city_name", function (value) {
            return true;
        }).value;
        var name_kana = useField("name_kana", yup
            .string()
            .required(state.aLang.validation_required)
            .max(100, agh.sprintf(state.aLang.validation_max_length, 100))
            .matches(Constant.KANA_PATTERN, state.aLang.validation_format_kana)).value;
        var project_place_prefecture_id = useField("project_place_prefecture_id", yup
            .string()
            .required(state.aLang.validation_required)).value;
        var project_place_city_id = useField("project_place_city_id", yup
            .string()
            .required(state.aLang.validation_required)).value;
        var family_name = useField("family_name", yup
            .string()
            .required(state.aLang.validation_required)
            .max(64, agh.sprintf(state.aLang.validation_max_length, 64))).value;
        var given_name = useField("given_name", yup
            .string()
            .required(state.aLang.validation_required)
            .max(64, agh.sprintf(state.aLang.validation_max_length, 64))).value;
        var family_name_kana = useField("family_name_kana", yup
            .string()
            .required(state.aLang.validation_required)
            .max(64, agh.sprintf(state.aLang.validation_max_length, 64))
            .matches(Constant.KANA_PATTERN, state.aLang.validation_format_kana)).value;
        var given_name_kana = useField("given_name_kana", yup
            .string()
            .required(state.aLang.validation_required)
            .max(64, agh.sprintf(state.aLang.validation_max_length, 64))
            .matches(Constant.KANA_PATTERN, state.aLang.validation_format_kana)).value;
        var phone_number = useField("phone_number", yup
            .string()
            .required(state.aLang.validation_required)
            .matches(Constant.PHONE_PATTERN, { message: state.aLang.validation_format_phone_number, excludeEmptyString: true })
            .transform(function (value, originalValue) {
            return originalValue.trim() === "" ? "" : value;
        })).value;
        var is_multiple_matching = useField("is_multiple_matching", yup
            .boolean()).value;
        // end validate
        var onSubmit = handleSubmit(function (values) {
            values = Object.assign(store.state.organizationResgisterData, values);
            store.commit("setOrganizationResgisterData", values);
            router.push("./confirm");
        });
        var timeout = null;
        function onInputName(inputName) {
            if (timeout) {
                clearTimeout(timeout);
            }
            if (!inputName.target.value) {
                state.listCorporation = [];
                state.showDropdownList = false;
                state.loadingDataDropdown = false;
                return;
            }
            state.showDropdownList = true;
            state.loadingDataDropdown = true;
            values.corporate_number = "";
            timeout = setTimeout(function () {
                getAllCorporation(inputName.target.value);
            }, 500);
        }
        function onFocus() {
            if (!state.listCorporation || state.listCorporation.length == 0) {
                return;
            }
            state.showDropdownList = true;
        }
        function onOutFocus() {
            if (timeout) {
                clearTimeout(timeout);
            }
            state.showDropdownList = false;
        }
        function selectCorporation(corporation) {
            if (!corporation) {
                return;
            }
            state.showDropdownList = false;
            var prefectureName = getPerfectureName(corporation.prefectureCode);
            setValues({
                name: corporation.name,
                project_place_prefecture_id: Number(corporation.prefectureCode).toString(),
                project_place_prefecture_name: prefectureName,
                project_place_city_id: Number(corporation.cityCode).toString(),
                corporate_number: corporation.corporateNumber
            });
            setTimeout(function () {
                getCitiesByPerfecture(corporation.prefectureCode);
            }, 100);
        }
        function getAllCorporation(keyword) {
            if (keyword === void 0) { keyword = ""; }
            if (!keyword) {
                state.listCorporation = [];
                state.loadingDataDropdown = false;
                state.showDropdownList = false;
                return;
            }
            return masterInteractor.searchCorporations(keyword).then(function (result) {
                state.loadingDataDropdown = false;
                if (!result.results) {
                    return;
                }
                state.listCorporation = result.results;
            }).catch(function () {
                state.loadingDataDropdown = false;
            });
        }
        function getAllPrefecture() {
            return masterInteractor.getAllPerfecture().then(function (result) {
                if (!result.data) {
                    return;
                }
                state.listPerfecture = result.data;
                if (values.project_place_prefecture_id) {
                    var prefectureName = getPerfectureName(values.project_place_prefecture_id);
                    setValues({
                        project_place_prefecture_id: values.project_place_prefecture_id,
                        project_place_prefecture_name: prefectureName
                    });
                }
            });
        }
        function getCitiesByPerfecture(prefecture_id) {
            if (!prefecture_id) {
                return;
            }
            return masterInteractor
                .getAllCitiesByPerfecture(prefecture_id)
                .then(function (result) {
                if (!result.data) {
                    return;
                }
                state.listCities = result.data;
                if (store.state.organizationResgisterData
                    .project_place_city_id) {
                    setValues({
                        project_place_city_id: store.state.organizationResgisterData
                            .project_place_city_id
                    });
                }
                if (values.project_place_city_id) {
                    if (checkExistCities(values.project_place_city_id)) {
                        var cityName = getCityName(values.project_place_city_id);
                        setValues({
                            project_place_city_name: cityName
                        });
                    }
                    else {
                        setValues({
                            project_place_city_id: ""
                        });
                    }
                }
                else {
                    setValues({
                        project_place_city_id: ""
                    });
                }
            });
        }
        function checkExistCities(project_place_city_id) {
            var cities = state.listCities.findIndex(function (o) { return o.id == project_place_city_id; });
            return cities != -1 ? true : false;
        }
        function onChangePrefecture(prefectureId) {
            state.listCities = [];
            if (!prefectureId) {
                return;
            }
            var prefectureName = getPerfectureName(prefectureId);
            setValues({
                project_place_city_id: "",
                project_place_prefecture_name: prefectureName
            });
            getCitiesByPerfecture(prefectureId);
        }
        function onChangeCity(cityId) {
            if (!cityId) {
                return;
            }
            var cityName = getCityName(cityId);
            setValues({
                project_place_city_id: cityId,
                project_place_city_name: cityName
            });
        }
        function getPerfectureName(id) {
            var perfecture = state.listPerfecture.filter(function (o) { return o.id == id; })[0];
            return perfecture ? perfecture.name : "";
        }
        function getCityName(id) {
            var city = state.listCities.filter(function (o) { return o.id == id; })[0];
            return city ? city.name : "";
        }
        onMounted(function () {
            getAllPrefecture();
            var organizationResgisterData = store.state.organizationResgisterData;
            if (!organizationResgisterData.email) {
                return router.push(RoutePath.LOGIN);
            }
            if (!organizationResgisterData.name) {
                return;
            }
            var _a = organizationResgisterData || {}, family_name = _a.family_name, given_name = _a.given_name, family_name_kana = _a.family_name_kana, given_name_kana = _a.given_name_kana;
            setValues({
                name: organizationResgisterData.name,
                name_kana: organizationResgisterData.name_kana,
                project_place_prefecture_id: organizationResgisterData.project_place_prefecture_id,
                phone_number: organizationResgisterData.phone_number,
                corporate_number: organizationResgisterData.corporate_number,
                is_multiple_matching: organizationResgisterData.is_multiple_matching,
                family_name: family_name,
                given_name: given_name,
                family_name_kana: family_name_kana,
                given_name_kana: given_name_kana
            });
            getCitiesByPerfecture(organizationResgisterData.project_place_prefecture_id);
        });
        return {
            state: state,
            onSubmit: onSubmit,
            onInputName: onInputName,
            onFocus: onFocus,
            onOutFocus: onOutFocus,
            selectCorporation: selectCorporation,
            onChangePrefecture: onChangePrefecture,
            onChangeCity: onChangeCity,
            values: values,
            isSubmitting: isSubmitting,
            errors: errors
        };
    }
});
