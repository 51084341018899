<template>
    <div class="organization_register_profile">
        <div class="organization_register_profile__top">

            <div class="organization_register_profile__top__step">
                <span>
                    ログイン情報入力
                </span>
                <span class="active">
                    企業情報登録
                </span>
                <span>
                    登録内容確認
                </span>
                <span>
                    メール確認
                </span>
                <span>
                    ご利用登録完了
                </span>
            </div>
            <p class="organization_register_profile__top-title">
                企業情報登録
            </p>
        </div>
        <div class="organization_register_profile__body">
            <div class="mw-1000">
                <div class="organization_register_profile__body-message">
                    貴社の企業情報をご入力ください。
                </div>
                <div class="organization_register_profile__body__form">
                    <form class="horizontal" @submit="onSubmit">
                        <div class="form-ctrl">
                          <label for="name" class="text">企業名<span>（必須）</span></label>
                            <div class="form-group">
                                <div class="form-item">
                                    <Input
                                        maxlength="100"
                                        type="text"
                                        v-model="values.name"
                                        autocomplete="off"
                                        @keyup="onInputName"
                                        placeholder="ご入力ください"
                                        @focus="onFocus"
                                        v-click-outside="onOutFocus"
                                        required="true"
                                    />
                                    <div
                                        v-if="
                                            state.showDropdownList
                                        "
                                        class="dropdown-list"
                                    >
                                        <template
                                            v-if="state.listCorporation.length > 0 && !state.loadingDataDropdown"
                                        >
                                            <a
                                                v-for="corporation in state.listCorporation"
                                                :key="
                                                    corporation.corporateNumber
                                                "
                                                @click="
                                                    selectCorporation(
                                                        corporation
                                                    )
                                                "
                                                >{{ corporation.name + " (" + corporation.prefectureName + corporation.cityName + corporation.streetNumber + ")" }}</a
                                            >
                                        </template>
                                        <p v-if="state.listCorporation.length == 0 && !state.loadingDataDropdown">検索結果のデータがありません。</p>
                                        <p v-if="state.loadingDataDropdown">Loading...</p>
                                    </div>
                                </div>
                                <ErrorMessage
                                    v-if="errors.name"
                                    :message="errors.name"
                                >
                                </ErrorMessage>
                            </div>
                        </div>
                        <div class="form-ctrl">
                            <label for="name_kana" class="text">（企業名）カナ<span>（必須）</span></label>
                            <div class="form-group">
                                <Input
                                    maxlength="100"
                                    id="name_kana"
                                    type="text"
                                    v-model="values.name_kana"
                                    name="name_kana"
                                    autocomplete="off"
                                    placeholder="会社形態は不要"
                                    required="true"
                                />
                                <ErrorMessage
                                    v-if="errors.name_kana"
                                    :message="errors.name_kana"
                                >
                                </ErrorMessage>
                            </div>
                        </div>
                        <div class="form-ctrl">
                            <label  class="text">所在地<span>（必須）</span></label>
                            <div class="form-group-row">
                                <div class="form-group">
                                    <select
                                        v-model="
                                            values.project_place_prefecture_id
                                        "
                                        @change="
                                            onChangePrefecture(
                                                values.project_place_prefecture_id
                                            )
                                        "
                                        :class="{
                                            invalid: !values.project_place_prefecture_id
                                        }"
                                        class="select"
                                    >
                                        <option value="" selected
                                            >都道府県選択</option
                                        >
                                        <template
                                            v-if="state.listPerfecture.length"
                                        >
                                            <option
                                                v-for="perfecture in state.listPerfecture"
                                                :key="perfecture"
                                                :value="perfecture.id"
                                                >{{ perfecture.name }}</option
                                            >
                                        </template>
                                    </select>
                                    <ErrorMessage
                                        v-if="
                                            errors.project_place_prefecture_id
                                        "
                                        :message="
                                            errors.project_place_prefecture_id
                                        "
                                    >
                                    </ErrorMessage>
                                </div>
                                <div class="form-group">
                                    <select
                                        :class="{
                                            invalid: !values.project_place_city_id
                                        }"
                                        class="select"
                                        @change="onChangeCity(values.project_place_city_id)"
                                        v-model="values.project_place_city_id"
                                    >
                                        <option value="" selected
                                            >市区町村選択</option
                                        >
                                        <template
                                            v-if="state.listCities.length"
                                        >
                                            <option
                                                v-for="cities in state.listCities"
                                                :key="cities"
                                                :value="cities.id"
                                                >{{ cities.name }}</option
                                            >
                                        </template>
                                    </select>
                                    <ErrorMessage
                                        v-if="errors.project_place_city_id"
                                        :message="errors.project_place_city_id"
                                    >
                                    </ErrorMessage>
                                </div>
                            </div>
                        </div>
                        <div class="form-ctrl">
                            <label for="name_kana" class="text">
                                担当者氏名<span>（必須）</span>
                                <br />
                                <span class="msg_private">※公開されません</span>
                            </label>
                            <div class="form-group-row">
                                <div class="form-group">
                                    <Input
                                        maxlength="64"
                                        id="family_name"
                                        type="text"
                                        v-model="values.family_name"
                                        name="family_name"
                                        autocomplete="off"
                                        required="true"
                                        placeholder="苗字をご入力ください"
                                    />
                                    <ErrorMessage
                                        v-if="errors.family_name"
                                        :message="errors.family_name"
                                    >
                                    </ErrorMessage>
                                </div>
                                <div class="form-group">
                                    <Input
                                        maxlength="64"
                                        id="given_name"
                                        type="text"
                                        v-model="values.given_name"
                                        name="given_name"
                                        autocomplete="off"
                                        required="true"
                                        placeholder="名前をご入力ください"
                                    />
                                    <ErrorMessage
                                        v-if="errors.given_name"
                                        :message="errors.given_name"
                                    >
                                    </ErrorMessage>
                                </div>
                            </div>
                        </div>
                        <div class="form-ctrl">
                            <label for="name_kana" class="text">
                                （担当者氏名）カナ<span>（必須）</span>
                                <br />
                                <span class="msg_private">※公開されません</span>
                            </label>
                            <div class="form-group-row">
                                <div class="form-group">
                                    <Input
                                        maxlength="64"
                                        id="family_name_kana"
                                        type="text"
                                        v-model="values.family_name_kana"
                                        name="family_name_kana"
                                        autocomplete="off"
                                        required="true"
                                        placeholder="苗字カナをご入力ください"
                                    />
                                    <ErrorMessage
                                        v-if="errors.family_name_kana"
                                        :message="errors.family_name_kana"
                                    >
                                    </ErrorMessage>
                                </div>
                                <div class="form-group">
                                    <Input
                                        maxlength="64"
                                        id="given_name_kana"
                                        type="text"
                                        v-model="values.given_name_kana"
                                        name="given_name_kana"
                                        autocomplete="off"
                                        required="true"
                                        placeholder="名前カナをご入力ください"
                                    />
                                    <ErrorMessage
                                        v-if="errors.given_name_kana"
                                        :message="errors.given_name_kana"
                                    >
                                    </ErrorMessage>
                                </div>
                            </div>
                        </div>
                        <div class="form-ctrl">
                            <label for="name_kana" class="text">
                                連絡先電話番号<span>（必須）</span>
                                <br />
                                <span class="msg_private">※公開されません</span>
                            </label>
                            <div class="form-group-row">
                                <div class="form-group">
                                    <Input
                                        maxlength="11"
                                        autocomplete="off"
                                        id="phone_number"
                                        type="tel"
                                        v-model="values.phone_number"
                                        name="phone_number"
                                        required="true"
                                        placeholder="ハイフンなしでご入力ください"
                                    />
                                    <ErrorMessage
                                        v-if="errors.phone_number"
                                        :message="errors.phone_number"
                                    >
                                    </ErrorMessage>
                                </div>
                            </div>
                        </div>
                        <div class="form-ctrl">
                            <label for="multiple_matching" class="text">
                                2チームとのマッチングを<br />許可しますか？
                                <br />
                            </label>
                            <div class="form-group-row">
                                <div class="form-group">
                                    <input
                                        type="checkbox"
                                        id="multiple_matching"
                                        value="true"
                                        v-model="values.is_multiple_matching"
                                    /><label for="multiple_matching">許可する</label>
                                </div>
                            </div>
                            <span class="msg_private" style="margin-left: 250px;">※2チーム目を受け入れる場合、同時に2チームとの会議実施は不可のため、別途お時間をとっていただく必要があります。合計で会議が週に2時間程度必要となる点、その他質問対応も2倍程度必要になる点をご了承頂ける場合のみ☑️してください。
<br />※登録後変更することはできません</span>
                        </div>
                        <div class="btn_group">
                            <button
                                type="submit"
                                :disabled="isSubmitting"
                                :class="{ submitting: isSubmitting }"
                                class="btn-md btn-blue shadow mh10"
                            >
                                次へ
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" src="@/presentation/views/organization/register/detail-profile/DetailProfile.ts"></script>

<style lang="scss" scoped>
@import "@/presentation/views/organization/register/detail-profile/DetailProfile.scss";
</style>
