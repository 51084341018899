import { defineComponent, reactive, watchEffect } from "vue";
export default defineComponent({
    name: "Breadcrumb",
    props: ["breadcrumb"],
    setup: function (props) {
        var state = reactive({
            breadcrumb: props.breadcrumb
        });
        watchEffect(function () {
            state.breadcrumb = props.breadcrumb;
        });
        return {
            state: state
        };
    }
});
